exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-back-to-sports-js": () => import("./../../../src/pages/back-to-sports.js" /* webpackChunkName: "component---src-pages-back-to-sports-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-firmen-fitness-js": () => import("./../../../src/pages/firmen-fitness.js" /* webpackChunkName: "component---src-pages-firmen-fitness-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kleingruppentraining-js": () => import("./../../../src/pages/kleingruppentraining.js" /* webpackChunkName: "component---src-pages-kleingruppentraining-js" */),
  "component---src-pages-mamaworkout-babybauch-js": () => import("./../../../src/pages/mamaworkout-babybauch.js" /* webpackChunkName: "component---src-pages-mamaworkout-babybauch-js" */),
  "component---src-pages-mamaworkout-beckenboden-js": () => import("./../../../src/pages/mamaworkout-beckenboden.js" /* webpackChunkName: "component---src-pages-mamaworkout-beckenboden-js" */),
  "component---src-pages-personal-training-js": () => import("./../../../src/pages/personal-training.js" /* webpackChunkName: "component---src-pages-personal-training-js" */)
}

